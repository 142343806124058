<template>
<div class="ambassador-background">
  <Header />
     <div v-if="formSuccess" class="row">
            <div class="col-10 col-md-4 mx-auto ">
                <div  class=" contact-us-button text-center mt-50">
                    Format sent!
                </div>
            </div>
    </div>
     <div v-if="!showForm && !formSuccess" class="index-2 mt-5 mx-auto col-sm-12	col-md-10 col-xs-12	col-lg-10 col-xl-10 banner overflow-hidden">
            <div class="row mt-3">
                <div class="col-12 col-md-6 mx-auto">
                    <div class="row " >
                        <div class="col-9 mx-auto text-center">
                            <img class="ambassador" :src="ambassadorContent.TopImage.file" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 px-0 d-flex justify-content-center">
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                            <div class="mx-1 ambassador-title text-center">{{ambassadorContent.TopTitle}}</div>
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 m-auto">
                               <div class="text-center home-text"> {{ambassadorContent.TopSubtitle}} </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12 col-md-6 mx-auto">
                     <div class="row">
                        <div class="col-11 mx-auto text-center">
                            <img class="animation" :src="ambassadorContent.LeftImage.file" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 px-0 d-flex justify-content-center">
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                            <div class="mx-1 ambassador-title text-center">{{ambassadorContent.LeftTitle}}</div>
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 m-auto">
                               <div class="text-center home-text"> {{ambassadorContent.LeftSubtitle}} </div>
                        </div>
                    </div>
                </div>

                <div class="col-11 col-md-6 mx-auto">
                     <div class="row">
                        <div class="col-11 mx-auto text-center">
                            <img class="animation" :src="ambassadorContent.RightImage.file" />
                        </div>
                    </div>
                    <div class="row">
                          <div class="col-12 px-0 d-flex justify-content-center">
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                            <div class="mx-1 ambassador-title text-center">{{ambassadorContent.RightTitle}}</div>
                            <img src="@/assets/estrella.svg" class="ambassador-red-star mb-1 px-0" />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6 m-auto">
                               <div class="text-center home-text"> {{ambassadorContent.RightSubtitle}} </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>


                <div v-if="!showForm && !formSuccess" class="row mb-5 mt-5">
                
                <div class="col-8 col-md-6 m-auto d-flex justify-content-center">
                    <div class="m-auto text-center">
                        <button  v-on:click.prevent="changeShowForm();" class="contact-us-button">Request More Info<b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                    </div>
                </div>
            
            </div>
           <div v-if="!showForm" class="row mt-3 mb-4">
                <div class="col-4"></div>
                <div class="d-flex justify-content-center  col-4 p-0">
                    <div class="mr-2">
                        <a :href="Contacts.Whatsapp" target="_blank">  
                            <img class="workshop-contact" src="@/assets/whatsapp.svg" /> 
                        </a>
                    </div>
                    <div class="">
                        <a :href="Contacts.Mobile" target="_blank"> 
                            <img class="workshop-contact" src="@/assets/telefono.svg" /> 
                        </a>
                    </div>
                </div>
                <div class="col-4"></div>
            </div>






<!--FORM -->
         
            <div v-if="showForm && !formSuccess" class="index-2 mt-5 mx-auto col-sm-12	col-md-10 col-xs-12	col-lg-10 col-xl-10 banner overflow-hidden">
            <div class="row">
                <div class="col-11">
                </div>
                  <div class="col-md-1 col-1">
                      <div v-on:click="showForm=false" class="clickable"> 
                          <img src="@/assets/tache.svg" class="remove-x">
                      </div>
                  </div>
            </div>    
            <div class="row mt-3">
                 <div class="col-4 d-flex justify-content-center px-0 mx-0 ">
                        <img class="side-image" src="@/assets/certified-facilitator.png" />
                 </div>

                 <div class="col-4 d-flex justify-content-center">
                     <img class="upper-image" src="@/assets/ambassador.png" />
                 </div>

                 <div class="col-4 d-flex justify-content-center px-0 mx-0">
                    <img class="side-image" src="@/assets/practitioner.png" />
                 </div>
            </div>


    </div>


          <div v-if="showForm && !formSuccess" id="workshop-form" class=" mt-3 index-2 mx-auto col-sm-12 col-xs-12	col-md-8 ">
            <div class="row mb-4">
                 
                  <div class="col-1 d-flex justify-content-end px-0">
                        
                  </div>
                  <div class="col-10 px-0 d-flex justify-content-center">
                    <img src="@/assets/estrella.svg" class="quote-red-star mb-1" />
                    <div class="values-title align-bottom trxt-center">   Activate your powers   </div>
                    <img src="@/assets/estrella.svg" class="quote-red-star mb-1" />
                  </div>
                <div class="col-1 d-flex justify-content-start px-0">
                       
                  </div>
              </div>

            <div class="row">
               <!-- form -->
                <div class="col-12">
                 <b-form  >
               


                <div class="form-group">
                    <div class="row blue-form">
                <div for="name" class="col-4 form-label">*Name:</div>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <div for="email" class="col-4 form-label">*Email:</div>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                 <div class="form-group">
                    <div class="row blue-form">
                        <label for="mobile" label="*Mobile:" class="col-4">*Mobile:</label>
                            <div class="col-4 mr-0"> 
                                <select v-model="form.countryCode" class="menu-select py-2 pr-5">
                                    <option  v-for="(code, key)  in this.phoneCodes" :key="key" :value="code.dial_code">{{code.code+code.dial_code}}</option>
                                </select>
                            </div>
                            <div class="col-4 ml-0 pl-0">
                                <input v-model="form.mobile" type="phone" class="mx-0 form-control blue-form" name="mobile" id="mobile">
                            </div>
                    </div>
                </div>


                 <div class="form-group">
                    <div class="row blue-form">
                        <div  class="col-4 pr-0 form-label mr-1">*I want to become:</div>
                        <div class="col-7 pl-0 ml-4"> 
                            <select v-model="form.interest" class="ltr-select px-5 py-3">
                                <option value="A Practitioner">A Practitioner</option>
                                <option value="A Facilitator">A Facilitator</option>
                                <option value="An Ambassador">An Ambassador</option>
                                <option value="All">All</option>
                            </select>
                        </div>
                    </div>
                </div>


                  <div class="form-group">
                    <div class="row blue-form">
                        <div  class="col-4 pr-0 form-label mr-1">*Preferred language:</div>
                        <div class="col-7 pl-0 ml-4"> 
                            <select v-model="form.language" class="ltr-select px-5 py-3">
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                
               <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-4">Comments:</label>
                    <div class="col-8 ">
                            <textarea v-model="form.comments"  
                            rows="4" 
                            class="form-control comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold; border-color:#007bff1a;
                                    width:95%; font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;
                                    "
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-8 form-check d-flex justify-content-center">
                     <div class="align-self-center">
                        <input type="checkbox" v-model="form.privacyPolicy" value=true class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1"> 
                                  <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>

                        </label>
                     </div>
                </div>

                 <div class="col-4 form-check px-0 d-flex justify-content-end">
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="contact-us-button" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
                </div>
               <!--enf form -->
        </div>
        </div>

  <Footer class="mt-5"/>
</div>
</template>

<script>
// S E R V I C E S



import EmailService from "@/service/email"

import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import phoneCodes from "@/data/phoneCodes"
import AmbassadorService from "@/service/ambassador.js"
import ContactService from "@/service/contact"

  export default {
     components: {
       Header,
       Footer
  },
  mounted(){
      window.scrollTo(0,0);
       this.getAmbassadorContent();

      this.getContacts();
  },
    data() {
      return {
        jobs: [],
        isLoged:false,
        showForm:false,
        formSuccess:false,
        Contacts:{
            Whatsapp: '',
            LinkiedIn: '',
            Youtube: '',
            Mobile: ''
        },
        form:{
            name:"",
            mobile:"",
            countryCode:"+52",
            email:"",
            comments:"",
            interest:"",
            privacyPolicy:false,
            subject:"Become an ambassador"
        },
        ambassadorContent:{
            TopImage: {
                file:'',
                name:''
            },
            TopTitle: '',
            TopSubtitle: '',
            RightImage: {
                file:'',
                name:''
            },
            RightTitle: '',
            RightSubtitle: '',
            LeftImage: {
                file:'',
                name:''
            },
            LeftTitle: '',
            LeftSubtitle: ''
        },
        phoneCodes : []
      }
    },

    computed:{

    isFormfilled(){
        if(this.form.name !="" &&
            this.form.mobile !="" &&
            this.form.email !="" &&
            this.form.comments !="" &&
            this.form.interest !="" &&
            this.form.privacyPolicy == true
            ){     
                return false;
            }
            return true;

    },

    disabledClass(){
        if(this.isFormfilled){
            return "disabled-button";
        }
        return "";
    }

    },

     created() {
    this.phoneCodes = phoneCodes.phoneCodes;
  },

    
       watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showForm = false;
          }, 3000);
        }
      }
    },
    methods:{
      send(){
          EmailService.sendEmail(this.form).then(()=>{
            window.scrollTo(0,0);
            this.form = {};
            this.showForm = false;
            this.formSuccess = true;
          }).catch(()=>{
            console.log('error')
          })
      },

        getContacts(){
        ContactService.getContacts().then((res)=>{
            this.Contacts.Whatsapp =res.data.Whatsapp;
            this.Contacts.LinkiedIn =res.data.LinkiedIn;
            this.Contacts.Youtube =res.data.Youtube;
            this.Contacts.Mobile =res.data.Mobile;
        })
    },

       getAmbassadorContent(){
        AmbassadorService.getAmbassadorContent().then((res)=>{
            this.ambassadorContent.TopImage.file = res.data.TopImage;
            this.ambassadorContent.TopTitle = res.data.TopTitle;
            this.ambassadorContent.TopSubtitle = res.data.TopSubtitle;
            this.ambassadorContent.RightImage.file = res.data.RightImage;
            this.ambassadorContent.RightTitle = res.data.RightTitle;
            this.ambassadorContent.RightSubtitle = res.data.RightSubtitle;
            this.ambassadorContent.LeftImage.file = res.data.LeftImage;
            this.ambassadorContent.LeftTitle = res.data.LeftTitle;
            this.ambassadorContent.LeftSubtitle = res.data.LeftSubtitle;
            })
        },
  
      changeShowForm(){
          window.scrollTo(0,0);
          this.showForm = !this.showForm;
          console.log(this.showForm);
      },



      logout(){
        sessionStorage.clear();
        this.isLoged = false;
      }
      
    }
  }
</script>


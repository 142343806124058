import axios from 'axios';

function getAmbassadorContent(){
  
    return axios({
        method: 'get',
        url: '/api/public/ambassador.php'})
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});
}



export default{
    getAmbassadorContent
};